@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,500;1,100&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100vh;
  background-color: white;
}

body {
  height: 100%;
}

#root {
  height: 100%;
}

@layer components {
  .prevCalendarButton {
    padding: 3px;
    position: absolute;
    top: 20px;
    right: 33px;
    width: 40px;
  }
  .nextCalendarButton {
    padding: 3px;
    position: absolute;
    right: 12px;
    top: 20px;
  }
  .btn-primary {
    @apply bg-primary rounded-md shadow-sm text-center leading-4 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary px-2 py-3 box-border transition ease-in-out delay-150 hover:shadow-lg duration-300;
  }

  .btn-secondary {
    @apply bg-secondary rounded-md shadow-sm text-center leading-4 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary px-2 py-3 box-border transition ease-in-out delay-150 hover:shadow-lg duration-300;
  }

  .btn-tertiary {
    @apply bg-tertiary rounded-md shadow-sm text-center leading-4 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-tertiary px-2 py-3 box-border;
  }

  .btn-back {
    @apply text-tertiary rounded-md shadow-sm text-right leading-4 font-medium border border-2 border-tertiary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-white px-2 py-3 box-border hover:bg-tertiary hover:text-white transition ease-in-out delay-150 hover:scale-110 duration-300;
  }

  .text-title {
    @apply font-body tracking-tight text-2xl font-body;
  }
  .text-subtitle {
    @apply font-body font-medium tracking-tight text-lg leading-7;
  }
  .text-content {
    @apply font-body tracking-tight leading-6;
  }
}

.DayPicker_weekHeader {
  top: 55px !important;
}

.CalendarDay {
  padding: 0px !important;
}

.CalendarDay__highlighted_calendar {
  background: #5d75f9 !important;
}

/* Custom style */

.my-project-cron {
  @apply flex flex-col sm:flex-row p-4 sm:items-center shadow-md;
}
.my-project-cron-field {
  flex-direction: column;
  align-items: flex-start !important;
  @apply font-body text-primary text-base px-2;
}
.ant-select-selection-placeholder {
  @apply text-sm sm:text-base;
}
.ant-select-selection-item {
  @apply text-sm sm:text-base;
}
.my-project-cron-select {
  margin-left: 0px !important;
}
.ant-select-selector {
  @apply pl-3 pr-4 text-black border-gray-300 text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md !important;
}
.ant-select-arrow {
  font-color: #6b7280 !important;
  margin-top: -5px !important;
  @apply pl-7px;
}
.my-project-cron .my-project-cron-select > div:first-child {
  border-radius: 10px;
}
.my-project-cron-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #dadada;
}
.my-project-cron-clear-button {
  border-radius: 10px;
}

.react-datepicker-wrapper {
  width: 100%;
}
